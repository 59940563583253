<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form ref="banktobankForm" @submit.prevent="saveB2BTransaction" class="custom-form">
                    <v-card color="white shadow1 rounded mb-4">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar :elevation="0" style="border-bottom: 1px solid #ddd !important;">
                                    <v-toolbar-title class="subtitle-1">Bank To Bank Transaction Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row justify="center">
                                        <v-col cols="12">
                                            <v-row no-gutters>
                                                <v-col cols="5">
                                                    <v-row no-gutters class="mb-1 align-center">
                                                        <v-col cols="3">Txn. ID</v-col>
                                                        <v-col cols="9">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                readonly
                                                                v-model="banktobank.txn_id"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters class="mb-1 align-center">
                                                        <v-col cols="3">Debit Account</v-col>
                                                        <v-col cols="9">
                                                            <v-combobox
                                                                dense
                                                                outlined
                                                                hide-details
                                                                item-text="display_text"
                                                                item-value="id"
                                                                @focus="$store.dispatch('bankAccount/getBankAccounts')"
                                                                :items="$store.getters['bankAccount/accounts']"
                                                                :loading="$store.getters['bankAccount/loadingBankAccount']"
                                                                v-model="selectedDebitBank"
                                                                :rules="[v => !!v]"
                                                            >
                                                            </v-combobox>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row no-gutters class="mb-1 align-center">
                                                        <v-col cols="3">Credit Account</v-col>
                                                        <v-col cols="9">
                                                            <v-combobox
                                                                dense
                                                                outlined
                                                                hide-details
                                                                item-text="display_text"
                                                                item-value="id"
                                                                @focus="$store.dispatch('bankAccount/getBankAccounts')"
                                                                :items="filteredCreditAccounts"
                                                                :loading="$store.getters['bankAccount/loadingBankAccount']"
                                                                v-model="selectedCreditBank"
                                                                :rules="[v => !!v]"
                                                            >
                                                            </v-combobox>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="5">
                                                    <v-row no-gutters class="mb-1 align-center"> 
                                                        <v-col cols="3" class="text-right pr-2">Tnx. Date</v-col>
                                                        <v-col cols="9">
                                                            <v-menu
                                                                v-model="menu"
                                                                :close-on-content-click="false"
                                                                :nudge-right="0"
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="auto"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        v-model="banktobank.date"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        autocomplete="off"
                                                                    >
                                                                        <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                                    </v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="banktobank.date"
                                                                    @input="menu = false"
                                                                >
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters class="mb-1 align-center">
                                                        <v-col cols="3" class="text-right pr-2">Amount</v-col>
                                                        <v-col cols="9">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                :rules="[v => !!v]"
                                                                v-model.number="banktobank.amount"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters class="mb-1 align-center">
                                                        <v-col cols="3" class="text-right pr-2">Description</v-col>
                                                        <v-col cols="9">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model.trim="banktobank.note"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="3"></v-col>
                                                        <v-col cols="9">
                                                            <v-row no-gutters>
                                                                <v-col cols="6" class="pr-1">
                                                                    <v-btn type="submit" :loading="logading" dark block color="text_bg_fave">Save</v-btn>
                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <v-btn @click="resetForm" dark block color="deep-orange">Clear</v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="2" class="pa-3">
                                                    <v-row no-gutters justify="center">
                                                        <v-col cols="12">
                                                            <v-card class="py-2 px-2">
                                                                <fieldset class="current__balance">
                                                                    <legend>Current Balance</legend>
                                                                    <div class="content text-center">
                                                                        <div class="amount">{{ $store.getters['bankTransaction/currentBalance'].toFixed(2) }}</div>
                                                                        <div class="currency">DBT</div>
                                                                    </div>
                                                                </fieldset>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>

        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-4 px-4 shadow1 white rounded">
                <v-data-table
                    class="custom-data-table"
                    :headers="transactionHeaders"
                    :loading="$store.getters['bankToBank/loading']"
                    :items="$store.getters['bankToBank/transactions']"
                    :search="searchB2BTransactions"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="0">
                            <v-toolbar-title class="subtitle-1">B2B Transaction List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search B2B Transaction"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchB2BTransactions"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }" v-if="userType == 'super_admin' || userType == 'admin'">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editB2BTransaction(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="deleteId = item.id;$refs.confirmDialog.dialog = true" color="error" v-on="on">mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="confirmDialog" @confirm="deleteTransaction"></delete-confirm>
    </v-container>
</template>

<script>
import confirmDialog from "../../components/confirm-dialog.component";
export default {
    name: 'BankToBank',

    components: {
        'delete-confirm': confirmDialog,
    },

    data: ()=> ({
        transactionHeaders: [
            { text: 'Tnx. Date', value: 'format_date' },
            { text: 'Tnx. ID', value: 'txn_id' },
            { text: 'Debit Acc Name', value: 'debit_account.account_name' },
            { text: 'Acc Number', value: 'debit_account.account_number' },
            { text: 'Credit Acc Name', value: 'credit_account.account_name' },
            { text: 'Acc Number', value: 'credit_account.account_number' },
            { text: 'Description', value: 'note' },
            { text: 'Amount', value: 'amount' },
            { text: 'Saved By', value: 'added_by.name' },
            { text: 'Action', value: 'action' },
        ],
        searchB2BTransactions: '',

        banktobank: {
            id: null,
            txn_id: '',
            debit_account_id: null,
            credit_account_id: null,
            amount: 0,
            date: new Date().toISOString().slice(0, 10),
            note: '',
        },

        selectedDebitBank: {
            id: null,
            display_text: 'select debit bank'
        },
        selectedCreditBank: {
            id: null,
            display_text: 'select credit bank'
        },
        logading: false,
        deleteId: null,
        menu: false,
        userType: ''
    }),

    watch: {
        selectedDebitBank(bank) {
            if(bank == undefined) return;
            this.banktobank.debit_account_id = bank.id
            if (bank == null) {
                this.$store.commit('bankTransaction/setCurrentBalance', 0)
                return
            }
            this.$store.dispatch('bankTransaction/getCurrentBalance', {accountId: bank.id})
        },

        selectedCreditBank(bank) {
            if(bank == undefined) return;
            this.banktobank.credit_account_id = bank.id
        }
    },

    computed: {
        filteredCreditAccounts() {
            return this.$store.getters['bankAccount/accounts'].filter(account => account.id != this.selectedDebitBank.id);
        }
    },

    async created() {
        this.banktobank.txn_id = await this.$store.dispatch('bankToBank/generateTxnCode');
        await this.getB2BTransactions();

        let userData = JSON.parse(localStorage.getItem('userData'))
		this.userType = userData.userType;
    },

    methods: {
        async getB2BTransactions() {
            let filter = {
                dateFrom: this.banktobank.date,
                dateTo: this.banktobank.date
            }
            await this.$store.dispatch('bankToBank/getTransactions', filter)
        },

        async saveB2BTransaction() {
            if (!this.$refs.banktobankForm.validate()) return;

            if(this.selectedDebitBank.id == null) {
                this.$store.dispatch('snackbar/error', 'Select debit bank account');
                return;
            }

            if(this.selectedCreditBank.id == null) {
                this.$store.dispatch('snackbar/error', 'Select credit bank account');
                return;
            }

            if(this.banktobank.amount == 0) {
                this.$store.dispatch('snackbar/error', 'Amount is required');
                return;
            }

            if(+this.banktobank.amount > +this.$store.getters['bankTransaction/currentBalance']) {
                this.$store.dispatch('snackbar/error', 'Currently debit bank balance is insufficient');
                return;
            }

            this.loading = true
            let isSuccess = await this.$store.dispatch('bankToBank/saveTransaction', this.banktobank)
            if(isSuccess) {
                await this.getB2BTransactions();
                this.resetForm();
            }
            this.loading = false
        },

        async editB2BTransaction(item) {
            Object.keys(this.banktobank).map(k => this.banktobank[k] = item[k])
            this.selectedDebitBank = item.debit_account
            this.selectedDebitBank.display_text = `${_.get(item, 'debit_account.account_name') || 0} - ${_.get(item, 'debit_account.account_number')} - ${_.get(item, 'debit_account.bank_name')}`;
            this.selectedCreditBank = item.credit_account
            this.selectedCreditBank.display_text = `${_.get(item, 'credit_account.account_name') || 0} - ${_.get(item, 'credit_account.account_number')} - ${_.get(item, 'credit_account.bank_name')}`;
        },

        async deleteTransaction() {
            await this.$store.dispatch('bankToBank/deleteTransaction', this.deleteId)
            await this.getB2BTransactions();
            this.$refs.confirmDialog.dialog = false;
        },

        resetForm() {
            this.banktobank.id = null;
            this.banktobank.date = new Date().toISOString().slice(0, 10)
            this.banktobank.amount = 0
            this.banktobank.note = ''
            this.selectedDebitBank = {
                id: null,
                display_text: 'select debit bank'
            }
            this.selectedCreditBank = {
                id: null,
                display_text: 'select credit bank'
            }
            this.$refs.banktobankForm.resetValidation()
            this.$store.commit('bankTransaction/setCurrentBalance', 0)
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: 3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
</style>