export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            transactions: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        transactions(state) {
            return state.transactions.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },

    mutations: {
        loading(state, isLoading) {
            state.loading = isLoading;
        },

        setTransaction(state, transactions) {
            state.transactions = transactions;
        },
    },

    actions: {
        async generateTxnCode(context) {
            let newCode = await axios.get(`${this.state.host}/generate-b2b-txnid`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.code;
            })
            .catch(error => this.dispatch('snackbar/error', error))
    
            return newCode;
        },

        async getTransactions(context, payload) {
            context.commit('loading', true);

            let transactions = await axios.post(`${this.state.host}/get-b2b-transactions`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.transactions;

            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            context.commit('setTransaction', transactions);
            context.commit('loading', false);
        },

        async saveTransaction(context, payload) {
            let isSuccess = false;

            let url = '';

            if(payload.id != null) {
                url = 'update-b2b-transaction';
            } else {
                url = 'add-b2b-transaction';
                delete payload.id;
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });

            return isSuccess;
        },

        async deleteTransaction(context, id) {
            await axios.delete(`${this.state.host}/delete-b2b-transaction/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                // context.dispatch('getTransactions');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        },
    }
}